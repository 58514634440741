//General Imports
import React, { useState, useEffect, useRef, useContext } from "react";
import { graphql } from "gatsby";
import loadable from "@loadable/component";
import _ from "lodash";
import parse, { HTMLReactParserOptions } from "html-react-parser";
import { IntlProvider } from "react-intl";
import { defaultLanguage } from "../i18n";
import {
  isBrowser,
  isMobilePredicate,
  DebugModeLog,
  toggleElement,
} from "../utils";

//Context
import LocalesContext from "../context/LocalesContext";
import TrackingContext from "../context/TrackingContext";
import { UserContext } from "../context/UserContext";

//Layout
import Layout from "../components/Layout";
import HeaderScripts from "../components/HeadScripts";
import TaboolaWebPush from "../components/vendor/TaboolaWebPush";
import SeoStructure from "../components/SeoStructure";
import { Loader } from "../components/Loader";

// Loadable imports
const TaboolaWidget = loadable(
  () => import("../components/vendor/TaboolaWidget")
);
const Vidazoo = loadable(() => import("../components/vendor/Vidazoo"));
const AssertiveYield = loadable(
  () => import("../components/vendor/AssertiveYield")
);

// Types
import type { BlogPostProps } from "./template.types";
import type { TaboolaMode } from "../components/vendor/TaboolaWidget";

export function Head({ data, pageContext }: BlogPostProps): React.JSX.Element {
  const post = data.posts.nodes[0];
  const canonicalSlug = `/${pageContext.slug}`;
  const excerptWithoutTags = post.excerpt?.replace(/(<([^>]+)>)/gi, "");

  return (
    <>
      <HeaderScripts />
      {/* <Script
        async
        src={process.env.GATSBY_PUBSTACK_TAG}
        type="text/javascript"
      /> */}
      <SeoStructure
        title={post.title}
        description={excerptWithoutTags ? excerptWithoutTags : ""}
        slug={`${pageContext.locale}${canonicalSlug}`}
        uri={pageContext.uri}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            "@id": `${!post?.id ? "" : post?.id}`,
            datePublished: `${new Date(post?.date || "")}`,
            dateModified: `${new Date(post?.modified || "")}`,
            headline: `${!post?.title ? "" : post?.title}`,
            author: {
              "@type": "Person",
              name: `${
                !post?.author?.node?.name ? "" : post?.author?.node?.name
              }`,
            },
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": `${!post?.slug ? "" : post?.slug}`,
            },
            publisher: {
              "@type": "Organization",
              name: `${
                !process.env.GATSBY_SITE_NAME
                  ? ""
                  : process.env.GATSBY_SITE_NAME
              }`,
              logo: {
                "@type": "ImageObject",
                url: "",
              },
            },
            image: {
              "@type": "ImageObject",
              url: `${
                !post?.featuredImage?.node?.link
                  ? ""
                  : post?.featuredImage?.node?.link
              }`,
              width: `${
                !post?.featuredImage?.node?.width
                  ? ""
                  : post?.featuredImage?.node?.width
              }`,
              height: `${
                !post?.featuredImage?.node?.height
                  ? ""
                  : post?.featuredImage?.node?.height
              }`,
            },
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: `${post.title}`,
              },
            ],
          })}
        </script>
      </SeoStructure>
    </>
  );
}

// Counter for parallax IDs
let currentParallaxAdIndex = 0;

function BlogPost({
  data,
  pageContext,
}: BlogPostProps): React.JSX.Element | null {
  const post = data.posts.nodes[0];
  const allSections = !!post.nextPageTC ? post.nextPageTC : [];

  // Tells React that the page is ready, needs to be used for conditional elements
  const [pageReady, setPageReady] = useState<boolean | null>(null);

  const [isMobile, setIsMobile] = useState(false);

  const [currentListIndex, setCurrentListIndex] = useState(0);
  const [scrolledToSectionOne, setScrolledToSectionOne] = useState(false);
  const [scrolledToSectionThree, setScrolledToSectionThree] = useState(false);

  const currentListIndexRef = useRef(0);
  currentListIndexRef.current = currentListIndex;

  // State to trigger load more
  const [loadMore, setLoadMore] = useState(false);
  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(true);
  //Set a ref for the loading div
  const loadRef = useRef(null);

  //Setting utm_source & abtest
  const [utm, setUtm] = useState("");
  const { abtest } = useContext(TrackingContext);

  /**
   * Consent Taken runs twice here - needs investigation
   */
  const { consentTaken } = useContext(UserContext);

  // Shall we show Taboola?
  const showTaboola =
    isBrowser() &&
    utm &&
    !(
      utm.includes("ob-") ||
      utm.includes("Zemanta") ||
      utm.includes("nosource")
    );
  let taboolaPlacement = "Taboola Feed Desktop";
  let taboolaContainer = "TaboolaFeedDesktop";
  if (isMobile) {
    taboolaPlacement = "Taboola Feed Mobile";
    taboolaContainer = "TaboolaFeedMobile";
  }

  let parallaxAdIndex = 0;

  let isFacebookTemplate = false;
  if (
    (utm?.toLowerCase().startsWith("fb-") && isMobile) ||
    (utm?.toLowerCase().startsWith("twtr_") && !isMobile)
  ) {
    isFacebookTemplate = true;
  } else if (
    (utm?.toLowerCase().startsWith("mic_") && !isMobile) ||
    (utm?.toLowerCase().startsWith("light_") && !isMobile)
  ) {
    isFacebookTemplate = true;
  }

  // Store previous url to avoid repetition on URL page number update
  let prevUrl = "";
  const isVisible = (ele: Element) => {
    const { top, bottom } = ele.getBoundingClientRect();
    const vHeight = window.innerHeight || document.documentElement.clientHeight;
    return (top > 0 || bottom > 0) && top + 100 < vHeight;
  };

  const scrollHandler = function () {
    const sections = document.querySelectorAll(".section");
    const matcherSectionsUrls: string[] = [];
    sections.forEach((section) => {
      const url = section.getAttribute("data-url");
      if (isVisible(section) && url) {
        matcherSectionsUrls.push(url);
      }
    });

    const currentUrl = matcherSectionsUrls[matcherSectionsUrls.length - 1];

    if (currentUrl !== prevUrl) {
      window.history.replaceState({ path: currentUrl }, "", currentUrl);
    }

    prevUrl = matcherSectionsUrls[matcherSectionsUrls.length - 1];
  };

  // Handle intersection with load more div
  const handleObserver: IntersectionObserverCallback = (entities) => {
    const target = entities[0];

    if (target.isIntersecting) {
      setLoadMore(true);
    }
  };

  // Initialize the intersection observer API
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "10px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(handleObserver, options);

    if (loadRef.current) {
      observer.observe(loadRef.current);
    }

    // Setup listener for url modify
    window.addEventListener("scroll", scrollHandler);

    // Set utm source in state
    setUtm(window.localStorage.getItem("utm_source") || "");

    toggleElement("#main-footer", "hide");

    setPageReady(true);
  }, []);

  // Handle loading more articles
  useEffect(() => {
    if (!hasMore) {
      toggleElement("#main-footer", "show");
    }

    if (consentTaken && loadMore && hasMore) {
      if (window.assertive) {
        window.assertive.nextPage();
      }
      if (window.ayManagerEnv) {
        window.ayManagerEnv.changePage();
      }

      setCurrentListIndex((prevIndex) => {
        currentParallaxAdIndex++;

        if (currentListIndexRef.current === 0) {
          setScrolledToSectionOne(true);
        }
        if (currentListIndexRef.current === 2) {
          setScrolledToSectionThree(true);
        }

        return prevIndex + 1;
      });
      setLoadMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMore, hasMore, consentTaken]);

  //Check if there is more
  useEffect(() => {
    const isMore = currentListIndex < allSections.length;

    setHasMore(isMore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentListIndex]);

  useEffect(() => {
    const isMobile = isMobilePredicate();

    setIsMobile(isMobile);
  }, []);

  const setupOptioin = (index: number): HTMLReactParserOptions => {
    return {
      replace: (node) => {
        if (!("name" in node) || !("attribs" in node)) {
          return;
        }
        if (!isBrowser()) {
          return;
        }

        if (!node.attribs) {
          return;
        }

        if (node.attribs && node.attribs.src) {
          return (
            <>
              {pageReady && index === 0 && isMobile && !isFacebookTemplate && (
                <div className="code-block code-block--banner">
                  <div className="divider">
                    <span>Advertisement</span>
                  </div>
                  <div id="LeaderBelowTitle" className="ad-container" />
                </div>
              )}

              {pageReady && index > 0 && isMobile && (
                <div className="code-block code-block--banner">
                  <div className="divider">
                    <span>Advertisement</span>
                  </div>
                  <div
                    id={`MPU_Parallax_${index + parallaxAdIndex}`}
                    className="ad-container"
                  />
                </div>
              )}

              {pageReady && index > 0 && !isMobile && (
                <div className="code-block code-block--banner">
                  <div className="divider">
                    <span>Advertisement</span>
                  </div>
                  <div
                    id={`LeaderDesktopInfinite_${index + parallaxAdIndex}`}
                    className="ad-container"
                  />
                </div>
              )}

              <img
                src={node.attribs.src}
                referrerPolicy="no-referrer"
                alt=""
                loading={index === 0 ? "eager" : "lazy"}
                width={node.attribs.width}
                height={node.attribs.height}
              />

              {pageReady && isFacebookTemplate && index > 8 && isMobile && (
                <div className="code-block code-block--banner">
                  <div className="divider">
                    <span>Advertisement</span>
                  </div>
                  <div
                    id={`MPU_Parallax_${index + parallaxAdIndex}_b`}
                    className="ad-container"
                  />
                </div>
              )}

              {pageReady && !isFacebookTemplate && index > 8 && isMobile && (
                <div className="code-block code-block--banner">
                  <div className="divider">
                    <span>Advertisement</span>
                  </div>
                  <div
                    id={`MPU_Parallax_${index + parallaxAdIndex}_b`}
                    className="ad-container"
                  />
                </div>
              )}

              {pageReady && index === 1 && scrolledToSectionOne && (
                <div id="baidu-vidazoo-container">
                  <Vidazoo vidazooId="659d69a7ca46d867c34ed89b" utm={utm} />
                </div>
              )}

              {pageReady && index > 0 && index !== 1 && !isMobile && (
                <div className="code-block code-block--banner">
                  <div className="divider">
                    <span>Advertisement</span>
                  </div>
                  <div
                    id={`LeaderDesktopInfinite_${index + parallaxAdIndex + 1}`}
                    className="ad-container"
                  />
                </div>
              )}

              {pageReady && index === 0 && isMobile && !isFacebookTemplate && (
                <div className="code-block code-block--banner">
                  <div className="divider">
                    <span>Advertisement</span>
                  </div>
                  <div id="MPUTopRight" className="ad-container" />
                </div>
              )}
            </>
          );
        }

        return;
      },
    };
  };

  if (_.isEmpty(post)) return null;

  const locale =
    pageContext.locale !== defaultLanguage
      ? `/${pageContext.locale}`
      : `/${defaultLanguage}`;

  return (
    <>
      <article className="post post--inf">
        <div className="post-main">
          <header className="post-header">
            <h1 className="entry-title">{post.title}</h1>
            <div className="post-meta">
              <div className="post-author-name">
                <div className="author-by">By</div>
                <span>{post?.author?.node?.name}</span>
                <div className="author-line"> - </div>
              </div>
              <div className="post-date">{post.date}</div>
            </div>
          </header>

          <div className="post-content post-content--inf">
            {allSections.map((article, index) => {
              if (index > 1) {
                ++parallaxAdIndex;
              }

              //Temp fix to prevent replaceState URL difference (local vs prod)
              let siteUrl = pageContext.uri;
              let env: string = process.env.GATSBY_ENV
                ? process.env.GATSBY_ENV
                : "";
              if (env === "development") {
                siteUrl = siteUrl.replace(
                  env,
                  "http://local.greedyfinance.com:8000"
                );
              }

              return (
                <div
                  key={index}
                  className={`section`}
                  data-url={`${siteUrl}${
                    index + 1 === 1 ? "" : index + 1 + "/"
                  }`}
                  style={{
                    display: index > currentListIndex ? "none" : "block",
                  }}
                >
                  {parse(article, setupOptioin(index))}

                  {pageReady && index === 0 && !isMobile && (
                    <div className="code-block code-block--banner">
                      <div className="divider">
                        <span>Advertisement</span>
                      </div>
                      <div
                        id="LeaderDesktopInfinite"
                        className="ad-container"
                      ></div>
                    </div>
                  )}

                  {pageReady &&
                    isFacebookTemplate &&
                    index > 0 &&
                    index !== 2 &&
                    isMobile && (
                      <div className="code-block code-block--banner">
                        <div className="divider">
                          <span>Advertisement</span>
                        </div>
                        <div
                          id={`MPU_Parallax_${index + 1 + parallaxAdIndex}`}
                          className="ad-container"
                        />
                      </div>
                    )}

                  {pageReady &&
                    !isFacebookTemplate &&
                    index > 0 &&
                    isMobile && (
                      <div className="code-block code-block--banner">
                        <div className="divider">
                          <span>Advertisement</span>
                        </div>
                        <div
                          id={`MPU_Parallax_${index + 1 + parallaxAdIndex}`}
                          className="ad-container"
                        />
                      </div>
                    )}

                  {pageReady && index === 0 && isMobile && (
                    <div className="code-block code-block--banner">
                      <div className="divider">
                        <span>Advertisement</span>
                      </div>
                      <div id="MPUCenterRight" className="ad-container"></div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div ref={loadRef}>{hasMore ? <Loader /> : null}</div>

          {showTaboola && (
            <TaboolaWidget
              mode={"thumbnails-d" as TaboolaMode}
              container={taboolaContainer}
              placement={taboolaPlacement}
              utm={utm}
              className={hasMore ? "d-none" : ""}
            />
          )}

          {pageReady &&
            isFacebookTemplate &&
            isMobile &&
            scrolledToSectionThree && (
              <div className="code-block code-block--footer">
                <div className="divider">
                  <span>Advertisement</span>
                </div>
                <div id="StickyBanner" className="ad-container"></div>
              </div>
            )}
          {pageReady && !isFacebookTemplate && isMobile && (
            <div className="code-block code-block--footer">
              <div className="divider">
                <span>Advertisement</span>
              </div>
              <div id="StickyBanner" className="ad-container"></div>
            </div>
          )}
        </div>

        <aside className="post-ads-sidebar col-2">
          <div className="post-ads-sidebar__inner">
            <div className="divider">
              <span>Advertisement</span>
            </div>
            <div
              id="RightColumn"
              className="ad-container ad-container--sidebar"
            ></div>
          </div>
        </aside>
      </article>
    </>
  );
}

const BlogPosts = ({
  data,
  pageContext,
  location,
  params,
}: BlogPostProps): React.JSX.Element => {
  const avaiableLangs = [pageContext.locale];
  return (
    <>
      <TaboolaWebPush />
      <AssertiveYield />
      <IntlProvider locale={pageContext.locale} messages={pageContext.messages}>
        <LocalesContext.Provider
          value={{
            slug: pageContext.slug,
            avaiableLangs,
            pageNumber: 0,
          }}
        >
          <Layout containerClasses="container--center" type="post" layout="inf">
            <BlogPost
              data={data}
              pageContext={pageContext}
              location={location}
              params={params}
            />
          </Layout>
        </LocalesContext.Provider>
      </IntlProvider>
    </>
  );
};

export default BlogPosts;

export const query = graphql`
  query ($slug: String!) {
    avaiablePostTranslations: allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        language {
          slug
        }
      }
    }
    posts: allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        id
        title
        nextPageTC
        slug
        excerpt
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            link
            height
            width
          }
        }
        modified(formatString: "MMMM D, YYYY")
        date(formatString: "MMMM D, YYYY")
      }
    }
  }
`;
